import useDistricts from '@/composition/useDistricts';
import useYears from '@/composition/useYears';
import useDefaultValues from '@/composition/useDefaultValues';
import useStatusList from '@/composition/useStatusList';

const { defaultValues } = useDefaultValues();
const { areas, districts } = useDistricts();
const { years } = useYears();
const { defaultStatusId, load: loadStatusList } = useStatusList();

const getAreaList = () => {
  return areas.value.map(district => {
    return {
      value: district.district_id,
      text: district.district_name
    };
  });
};
const getDistrictList = areaId => {
  if (!areaId) return [];
  return [
    { value: null, text: 'Все' },
    ...districts.value(areaId).map(district => {
      return {
        value: district.district_id,
        text: district.district_name
      };
    })
  ];
};
const getYearList = () => {
  return [
    { value: null, text: 'Все' },
    ...years.value.map(year => {
      return {
        value: year,
        text: year
      };
    })
  ];
};

export default data => {
  return [
    {
      label: 'го/мр',
      fieldClass: 'px-20 mb-15 combobox-wrapper',
      component: 'uiSelect',
      componentAttrs: {
        items: getAreaList()
      },
      property: 'areaId',
      isNumber: true,
      default: defaultValues.value.AREA_ID,
      onChange() {
        data.value.districtId = null;
        loadStatusList({
          areaId: data.value.areaId,
          districtId: data.value.districtId,
          year: data.value.year
        });
      }
    },
    {
      label: 'район',
      fieldClass: 'px-20 mb-15 combobox-wrapper',
      component: 'uiSelect',
      componentAttrs: {
        items: getDistrictList(data?.value.areaId),
        disabled: !districts.value(data?.value.areaId).length
      },
      property: 'districtId',
      isNumber: true,
      default: null,
      onChange() {
        loadStatusList({
          areaId: data.value.areaId,
          districtId: data.value.districtId,
          year: data.value.year
        });
      }
    },
    {
      label: 'Период обнаружения',
      fieldClass: 'px-20 mb-30 combobox-wrapper',
      component: 'uiSelect',
      componentAttrs: {
        items: getYearList()
      },
      property: 'year',
      isNumber: true,
      default: null,
      onChange() {
        loadStatusList({
          areaId: data.value.areaId,
          districtId: data.value.districtId,
          year: data.value.year
        });
      }
    },
    {
      component: 'iopr-status-list',
      property: 'statusId',
      isNumber: true,
      default: defaultStatusId.value
    }
  ];
};
